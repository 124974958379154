import React from "react";
import { graphql } from "gatsby";
import { useMediaQuery } from "react-responsive";

import { Hero, Cards, CollectionsLinks, Seo } from "../components";
import { getHomepageCollections } from "../utils/common";
import Layout from "../components/Layout";

const Home = ({ data }) => {
  const slide = data.slides.nodes;
  const collections = getHomepageCollections(data);

  const IS_LARGE_SCREEN = useMediaQuery({
    query: "(min-width: 1900px)"
  });

  const calcAmountOfBooksToRender = books => {
    return IS_LARGE_SCREEN ? books : books.slice(0, 6);
  };

  return (
    <Layout>
      <Seo title="Home" />
      {slide[0].data && <Hero slide={slide[0].data} size="full" />}
      <CollectionsLinks />
      {collections.map((collection, idx) => {
        if (!collection.cards.length) return;

        return (
          <Cards
            key={idx}
            title={collection.title}
            cards={calcAmountOfBooksToRender(collection.cards)}
            link={collection.link}
          />
        );
      })}
    </Layout>
  );
};

export const query = graphql`
  {
    sale: allAirtable(
      filter: {
        data: { categories: { eq: "Sale" }, status: { eq: "Available" } }
      }
      sort: { fields: data___Release_Date, order: DESC }
      limit: 10
    ) {
      nodes {
        data {
          ...CardParts
        }
      }
    }
    recent: allAirtable(
      filter: {
        data: { categories: { eq: "Recent" }, status: { eq: "Available" } }
      }
      sort: { fields: data___Release_Date, order: DESC }
      limit: 10
    ) {
      nodes {
        data {
          ...CardParts
        }
      }
    }
    featured: allAirtable(
      filter: {
        data: { categories: { eq: "Featured" }, status: { eq: "Available" } }
      }
      sort: { fields: data___Release_Date, order: DESC }
      limit: 10
    ) {
      nodes {
        data {
          ...CardParts
        }
      }
    }
    diaries: allAirtable(
      filter: {
        data: {
          categories: { eq: "City Diaries" }
          status: { eq: "Available" }
        }
      }
      sort: { fields: data___Release_Date, order: DESC }
      limit: 10
    ) {
      nodes {
        data {
          ...CardParts
        }
      }
    }
    slides: allAirtable(filter: { table: { eq: "slides" } }) {
      nodes {
        data {
          title
          subtitle
          link
          image {
            localFiles {
              childImageSharp {
                gatsbyImageData(placeholder: TRACED_SVG)
              }
            }
          }
        }
      }
    }
  }
`;

export default Home;
